@use '../abstracts/mixins' as *;

.footer {
  background-color: var(--clr-neutral-1000);
  color: var(--clr-primary-400);
  padding: 2rem 3rem;
  margin-block-start: 5rem;

  &__menu-list {
    list-style: none;
    padding: 0;
    display: inline-flex;
    gap: 1rem;
    align-items: center;
  }

  &__link {
    font-weight: 700;
  }
}

@include mq(small) {
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include mq(medium) {
  .footer {
    padding: 2rem 5rem;
  }
}
