@use '../abstracts/mixins' as *;
@use '../utilities/container';

.article-page {
  &__container {
    @extend .container !optional;
    position: relative;
  }

  &__content {
    grid-area: content;

    .primary-image {
      margin-block-end: 2rem;
    }

    & > .streamfield {
      --spacer: 2rem;
      width: 100%;
    }
  }
}

@include mq(medium) {
  .article-page {
    &__container {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 300px;
      grid-template-areas: 'content aside';
      gap: 2rem;
      align-items: start;
    }
  }
}
