@use '../abstracts/mixins' as *;

.header {
  font-size: var(--fs-500);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 4px solid var(--clr-primary-400);

  &__logo {
  }
}

@include mq(medium) {
  .header {
    padding: 2rem 5rem;
  }
}
