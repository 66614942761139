@use '../abstracts/mixins' as *;

.container {
  width: min(100vw - 3rem, 1024px);
  margin-inline: auto;
}

@include mq(big) {
  .container {
    --spacer: 5rem;
    padding: 0 9.375rem;
  }
}
