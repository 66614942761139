@use '../abstracts/mixins' as *;

.article-teaser {
  text-decoration: none;
  margin-block-end: 0 !important;

  & + & {
    margin-block-start: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &__content {
    padding: 2rem;
    background-color: var(--clr-primary-400);
    text-decoration: none;
  }

  &:hover &__content {
    text-decoration: none;
  }

  &__image {
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  &__read-more {
    margin-block-start: 1em;
    font-weight: 700;
  }

  &:hover &__read-more {
    text-decoration: underline;
  }
}

@include mq(medium) {
  .article-teaser {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    &--reversed {
      grid-auto-flow: dense;
    }

    &--reversed &__content {
      grid-column: 1;
    }

    &--reversed &__image {
      grid-column: 2;
    }

    &__content {
      padding: 4rem;
    }
  }
}
