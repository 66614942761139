@use '../utilities/container';

.search-box {
  @extend .container !optional;

  display: grid;
  gap: 1rem;

  margin-block-end: 5rem;

  &__search-field {
    width: 100%;
    display: flex;
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 3px solid var(--clr-primary-400);

    input {
      flex-grow: 1;
      border: none;
      padding-inline: 1rem;
    }

    button[type='submit'] {
      border: none;
      background-color: var(--clr-primary-400);
      padding: 0.75rem clamp(1rem, 3vw, 2rem);
      color: white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        stroke: var(--clr-neutral-1000);
        stroke-width: 6px;
      }
    }
  }

  &__tags {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-block: 1.5rem;
  }

  &__tag {
    input {
      visibility: hidden;
      width: 0px;
      height: 0px;
      position: absolute;
    }

    label {
      border: 2px solid var(--clr-primary-400);
      padding: 0.75rem 1.5rem;
      border-radius: 1000px;
      display: inline-block;
    }

    input:checked + label {
      background-color: var(--clr-primary-400);
    }
  }
}
