@use '../abstracts/mixins' as *;
@use '../utilities/container';

.content-marketing {
  background-color: var(--clr-neutral-200);
  width: 100%;
  padding-block: 4rem;

  &__logo {
    width: 200px;
    height: 200px;
    margin-inline: auto;
    display: block;
  }

  &__content {
  }

  &__link {
    display: inline-block;
    background-color: var(--clr-primary-400);
    color: var(--clr-neutral-1000);
    font-weight: 700;
    padding: 0.5rem 1.25rem;
    border-radius: 5px;
    margin-block-start: 2rem;
  }
}

@include mq(medium) {
  .content-marketing {
    &__container {
      --spacer: 0;
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-block: 0;
    }
    &__logo {
      width: 300px;
      height: 300px;
      flex-shrink: 0;
    }
  }
}
