.program {
  &__point {
    margin-block-end: 4rem;
  }

  &__point-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--clr-primary-400);
    margin-block-end: 0.5em;

    span:first-child {
      font-size: var(--fs-900);
      font-weight: 700;
    }

    span:last-child {
      height: 0.25rem;
      flex: 1;
      background-color: currentcolor;
    }
  }

  &__point-title {
    font-weight: 400;
    text-wrap: unset;

    strong::after {
      content: '|';
      display: inline-block;
      margin-inline: 1rem;
    }
  }
}
