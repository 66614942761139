@use '../abstracts/_mixins.scss' as *;

.article-search-teaser {
  &__content {
    padding: 1rem;
  }
  &:hover {
    text-decoration: none;
  }

  &:hover &__link-text {
    text-decoration: underline;
  }
}

@include mq(medium) {
  .article-search-teaser {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    grid-template-areas: 'image content';

    &:nth-child(even) {
      grid-template-areas: 'content image';
    }

    &__image {
      grid-area: image;
    }

    &__content {
      grid-area: content;
      padding: 4rem;
    }

    &__subtitle {
      font-size: var(--fs-600);
    }

    &__link-text {
      display: inline-block;
      font-size: var(--fs-600);
      margin-block-start: 2rem;
    }
  }
}
