.latest-articles {
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 250px), 1fr));
    gap: 1.5rem;
    margin-inline: auto;
    list-style: none;
    padding: 0;
  }

  &__list-item {
  }

  &__image {
    object-fit: contain;
    widht: 100%;
    height: auto;
  }

  &__list-item-content {
    padding: 1rem;
  }

  &__link {
    display: block;
    width: max-content;
    background-color: var(--clr-primary-400);
    color: var(--clr-neutral-1000);
    font-weight: 700;
    padding: 0.5rem 1.25rem;
    border-radius: 5px;
    margin-inline: auto;
  }
}
