@use '../abstracts/mixins' as *;

.article-meta-data {
  display: inline-block;
  font-size: var(--fs-400);
  width: 100%;
  margin-block: 2rem;

  p {
    margin: 0 auto;
    width: fit-content;

    .duration {
      font-weight: 700;
    }
  }
}
