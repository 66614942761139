@use '../abstracts/mixins' as *;
@use '../utilities/container';

.container-block {
  padding-block: 5rem;
  padding-inline: 1rem;
  background: var(--clr-primary-400, transparent);
  width: min(100vw - 3rem, 1440px);
  width: auto;

  .streamfield {
    --spacer: 2rem;
  }
}

@include mq(medium) {
  .container-block {
    padding-inline: 0;
  }
}
