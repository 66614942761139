@use '../abstracts/mixins' as *;

@include mq(medium, max) {
  .nav {
    flex-shrink: 0;
    &__list {
      display: none;
    }

    .hamburger[aria-expanded='true'] + &__list {
      display: block;
      position: absolute;
      background-color: white;
      padding: 0;
      left: 0;
      right: 0;
      margin-block-start: 2.125rem;
    }

    &__list-item {
      text-align: center;
    }

    &__link {
      display: inline-block;
      font-weight: 700;
      color: var(--clr-primary-400);
      padding: 0.5rem;
    }
  }
}
@include mq(medium) {
  .nav {
    color: var(--clr-primary-400);

    &__list {
      margin: 0;
      display: flex;
      gap: 2rem;
    }

    &__link {
      font-weight: 700;
    }
  }
}
