@use '../abstracts/mixins' as *;

.search {
  padding: clamp(1.25rem, 0.795rem + 2.27vw, 2.5rem) var(--screen-padding) 0;

  @include mq(small) {
    @include grid-12;
    padding-inline: 0;
  }

  margin-bottom: clamp(5rem, 4.56rem + 1.88vw, 6.25rem); //80 - 100px

  &__wrapper {
    grid-area: var(--2-11);
    margin-bottom: clamp(5rem, 4.56rem + 1.88vw, 6.25rem); //80 - 100px
  }

  .search-form {
    display: flex;
    margin-bottom: clamp(1.563rem, 1.012rem + 2.35vw, 3.125rem); // 25 - 50

    & > * {
      border: 2px solid var(--foreground-color);
      height: var(--search-form-height);
    }

    &__input {
      width: 100%;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;

      padding-inline: 1rem;
      background-color: var(--background-color);
      color: var(--foreground-color);
    }

    &__submit {
      width: clamp(5.313rem, 3.882rem + 6.1vw, 9.375rem); // 85 - 150px
      background-color: var(--foreground-color);
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      display: grid;
      place-content: center;
      transition: all 0.2s ease-in;

      .dark-theme & {
        background-color: var(--background-color);
      }

      &:focus,
      &:hover {
        background-color: var(--background-color);

        .dark-theme & {
          background-color: var(--foreground-color);
        }

        svg {
          path {
            fill: var(--foreground-color);

            .dark-theme & {
              fill: var(--background-color);
            }
          }
        }
      }

      svg {
        path {
          fill: var(--background-color);

          .dark-theme & {
            fill: var(--foreground-color);
          }
        }
      }
    }
  }
}
