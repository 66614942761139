@use '../abstracts/mixins' as *;

.search_result__link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: var(--screen-padding);
  padding: clamp(2.5rem, 2.28rem + 0.94vw, 3.125rem) 0; //40 - 50
  border-bottom: 1px solid var(--text);
  margin-inline: var(--screen-padding);

  &:last-of-type {
    border: none;
  }

  @include mq(small) {
    flex-direction: row;
  }

  .image__wrapper {
    aspect-ratio: 67 / 45;
    max-width: 335px;
    width: 100%;
    margin: 0 auto;

    @include mq(small) {
      max-width: 200px;
      display: grid;
      align-items: top;
    }
  }

  .text__wrapper {
    h2 {
      font-size: var(--heading-srp);
      margin-bottom: clamp(1.25rem, -0.94vw + 1.47rem, 0.625rem);
      text-wrap: balance;
    }

    p {
      line-height: 1.27;
      hyphens: auto;
    }
  }
}
