@use '../abstracts/mixins' as *;

body {
  color: var(--clr-neutral-1000);
  line-height: 1.85;
  //letter-spacing: -0.5px;
  font-size: var(--fs-500);
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-neutral-1000);
  font-weight: 700;
  text-transform: uppercase;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  text-wrap: balance;
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  color: var(--clr-primary-400);
  font-size: var(--fs-800);
  text-transform: uppercase;
  font-weight: 700;
}

h2,
.h2 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-700);
}

h3,
.h3 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-600);
}

h4,
.h4 {
  font-size: var(--fs-500);
}

small,
.text-small {
  font-size: var(--small);
}

strong {
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: underline;
  }
}

p {
  margin-bottom: var(--column);
}

// p:not([class]),
// ul:not([class]),
// li:not([class]) {
//   max-width: 60ch;
// }
