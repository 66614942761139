@use '../abstracts/mixins' as *;

.article-information {
  &__author {
    display: grid;
    gap: 1rem;
    justify-content: center;
    font-size: var(--fs-400);
  }

  &__author-image {
    aspect-ratio: 1 / 1;
    width: 125px;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    margin-inline: auto;
    object-fit: cover;
  }

  &__author-name {
    font-weight: 700;
    line-height: 1;
  }

  &__info {
    display: grid;
    justify-content: center;
    text-align: center;
  }
}

@include mq(medium) {
  .article-information {
    position: sticky;
    top: 11rem; // Need to compensate for sticky header aswell :(
    grid-area: 'aside';
    z-index: 0;

    &__author {
      display: flex;
      align-items: center;
    }

    &__info {
      text-align: left;
    }
  }
}
