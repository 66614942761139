@use '../abstracts/mixins' as *;

.article-page {
  .tags {
    display: grid;
    justify-content: center;
    font-size: var(--fs-400);
    margin-block: 1rem 3rem;
    line-height: 1;
    gap: 0.5rem;

    @include mq(medium) {
      margin: 0;
      padding: 0;
      border: none;
    }

    &__heading {
      font-weight: 700;
      text-align: center;
    }

    &__listing {
      text-transform: lowercase;
      margin: 0;
      display: inline;

      .tag {
        word-break: keep-all;
        text-decoration: none;
        text-transform: capitalize;

        &:not(:last-child)::after {
          content: ' · ';
          display: inline-block;
          text-decoration: none;
          padding-inline: 1ch;
        }
      }
    }
  }
}

@include mq(medium) {
  .article-page {
    .tags {
      margin-block: 3rem;
      justify-content: start;

      &__heading {
        text-align: start;
      }
    }
  }
}
